<template>
  <div
    style="
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <!--    <div class="py-4 text-h4">Storage Form</div>-->
    <form id="createForm" class="pa-3 pt-4 pb-0 row w-100 elevation-0">
      <div class="col-sm-6 col-md-6 col-12">
        <v-autocomplete
          v-model="formData.from_warehouse_id"  
          label="From"
          item-text="text"
          item-value="index"
          :items="serverData.stock_transfer.warehouses"
          clearable
          outlined
          :error-messages="from_warehouse_idErrors"
          @input="$v.formData.from_warehouse_id.$touch()"
          @blur="$v.formData.from_warehouse_id.$touch()"
        ></v-autocomplete>
      </div>

      <div class="col-sm-6 col-md-6 col-12">
        <v-autocomplete
          v-model="formData.to_warehouse_id"
          label="To"
          item-text="text"
          item-value="index"
          :items="serverData.stock_transfer.warehouses"
          clearable
          outlined
          :error-messages="to_warehouse_idErrors"
          @input="$v.formData.to_warehouse_id.$touch()"
          @blur="$v.formData.to_warehouse_id.$touch()"
        ></v-autocomplete>
      </div>
      <div class="col-12">
        <rate-datatable
          name="rate"
          :setSku="setRates"
          :skus="formData.rates"
          :hasImport="true"
          :importType="1"
          importLabel="Import rates"
          :exampleFile="serverData?.stock_transfer?.rates_sample_file"
        ></rate-datatable>
        <hr />

        <rate-datatable
          name="increment"
          :setSku="setIncrements"
          :skus="formData.increments"
          :hasImport="true"
          :importType="2"
          importLabel="Import increments"
          :exampleFile="serverData?.stock_transfer?.increments_sample_file"
        ></rate-datatable>
      </div>

      <v-main class="text-center mt-2 col-12 elevation-0">
        <button
          type="button"
          class="mx-1 btn btn-info px-5 py-3 ls1"
          @click="submitCreateForm"
        >
          Submit
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="resetCreateForm"
        >
          Clear
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="goBack"
        >
          Back
        </button>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import RateDatatable from "@/own/components/finance/services/addForms/rateContainer/RateDatatable";
export default {
  name: "StockTransferForm",
  components: { RateDatatable },
  mixins: [validationMixin, fieldValueValidation],
  props: ["setData", "actionFuntion", "serverData", "pageLoader", "initData"],
  validations() {
    return {
      formData: {
        from_warehouse_id: { required },
        to_warehouse_id: { required },
      },
    };
  },
  data: () => ({
    formData: {
      id: null,
      from_warehouse_id: null,
      to_warehouse_id: null,
      rates: [],
      increments: [],
    },
  }),
  methods: {
    setIncrements(item) {
      // console.log("before update", this.formData.increments, item);
      let copy = { ...this.formData };
      copy.increments = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.increments);
    },
    setRates(item) {
      // console.log("before update", this.formData.rates, item);
      let copy = { ...this.formData };
      copy.rates = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.rates);
    },
    goBack() {
      this.actionFuntion("back");
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        const data = Object.fromEntries(
          Object.entries(this.formData).filter(([key, value]) => value !== null)
        );
        this.setData(data);
        this.actionFuntion();
        // this.resetCreateForm();
      }
    },
    resetCreateForm() {
      // console.log("reset create form");
      this.$v.$reset();
      if (this.initData) {
        try {
          let copy = { ...this.serverData.service.values };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(this.serverData.service.values).forEach(
              (serverData) => {
                if (localData[0] == serverData[0]) {
                  this.formData[localData[0]] = copy[serverData[0]];
                }
              }
            );
          });
        } catch (e) {
          // console.error(e);
          this.formData = {
            id: null,
            from_warehouse_id: null,
            to_warehouse_id: null,
            rates: [],
            increments: [],
          };
        }
      } else {
        this.formData = {
          id: null,
          from_warehouse_id: null,
          to_warehouse_id: null,
          rates: [],
          increments: [],
        };
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    from_warehouse_idErrors() {
      return this.handleFormValidation("from_warehouse_id", this);
    },
    to_warehouse_idErrors() {
      return this.handleFormValidation("to_warehouse_id", this);
    },
  },
};
</script>
